define(['app', 'cookie', 'readmore', 'promoProductSlider', 'siteObj', 'viewport','$console', '$window'], function(app, cookie, readmore, promoProductSlider, siteObj, viewport,$console, $window) {
  const sponsoredProductsCriteo = function () {
    const component = {};

    const _config = {
      selectors: {
        widgetSection: '[data-js-element=sponsoredProducts_widgetList]',
        blocksSection: '[data-js-element=sponsoredProducts_blocksList]',
        readmoreSection: '[data-component=readmore]',
        productBlock: '[data-component=productBlock]',
        sliderSection: '[data-component=promoProductSlider]',
        productImage: '[data-track=product-image]',
        productTitle: '[data-track=product-title]',
        productQuickbuy: '[data-component=productQuickbuySimple]',
        productQuickbuySimple:  '[data-component=productQuickbuy]',
        productWishlist: '[data-context=addToWishlistButton]',
        productWishlistData: '[data-component=productAddToWishlist]',
        productId: 'data-product-id',
        beacons: {
          viewBeacon: 'data-beacon-view',
          loadBeacon: 'data-beacon-load',
          clickBeacon: 'data-beacon-click',
          wishlistBeacon: 'data-beacon-wishlist',
          basketBeacon: 'data-beacon-basket'
        }
      },
      attributes: {
        widgetVersion: 'data-widget-version',
        customerState: 'data-customer-state',
      },
      classes: {
        productBlockListClass: '.productListProducts_products',
        productBlockPlacementClass: '.productListProducts_dataWrapper_placement',
        productFlagshipListClass: '.promoProductSlider_slideWrap.flagship',
        productBlockClass: '.productListProducts_products',
        sponsoredBlocksClass: '.sponsoredProductsList',
        sponsoredFlagshipItemClass: '.promoProductSlider_slideItem.flagship',
        widgetBlockClass: '.sponsoredAdsProducts_item',
        sliderButton: '.promoProductSlider_navButton',
        sliderButtons: '.promoProductSlider_buttonWrap',
        sliderButtonNext: '.promoProductSlider_navButton-next',
        sliderCurrent: '.flagship.simpleSlider-current',
        sponsoredProductsFlagship: '.sponsoredProductsFlagship',
        sponsoredProductsInline: '.sponsoredProductsInline',
        sponsoredProductsWidgetList: '.sponsoredProducts_widgetList',
        sponsoredProductsFlagshipImage: '.sponsoredAdsProducts_content'
      },
      subscribeChannels: {
        wishlistBeacon: 'criteo/wishlistBeacon'
      }
    };

    const _init = function(element) {
      component.element = element;
      component.elementArray = [];

      component.readmoreInstance = readmore();
      component.successHandler();
      return component;
    };

    const _successHandler = function () {
      const returnBlocksSection = document.querySelector(component.config.selectors.blocksSection);
      const returnWidgetSection = document.querySelector(component.config.selectors.widgetSection);
      if (component.element === returnBlocksSection) {
        component.addBlocksTracking();
      }
      if (component.element === returnWidgetSection) {
        component.widgetSuccessHandler(returnWidgetSection);
      }
    };

    const _widgetSuccessHandler = function (componentDOM) {
      const returnFlagshipWidgetSection = document.querySelector(component.config.classes.sponsoredProductsFlagship);
      const returnWidgetSection = document.querySelector(component.config.classes.sponsoredProductsWidgetList);

      component.sendBeacon(componentDOM, component.config.selectors.beacons.loadBeacon);
      component.addPlacementTracking(componentDOM)

      if(component.element === returnFlagshipWidgetSection) {
        component.addFlagshipTracking();
      }
      if(component.element === returnWidgetSection) {
        component.addWidgetTracking(componentDOM);
      }

      const readmoreDOM = componentDOM.querySelector(component.config.selectors.readmoreSection);
      readmoreDOM && component.readmoreInstance.init(readmoreDOM);
      app.publish('tracking/record', 'SponsoredAds | WidgetNew', 'enabled');
    };

    const _addPlacementTracking = function (dataElemenet) {
      let viewSent = false;
      if(viewport.isElementFullyVisible(dataElemenet)) {
        component.sendBeacon(dataElemenet, component.config.selectors.beacons.viewBeacon);
        viewSent = true;
      } else {
        document.addEventListener('scroll', function() {
          if (!viewSent && viewport.isElementFullyVisible(dataElemenet)) {
            component.sendBeacon(dataElemenet, component.config.selectors.beacons.viewBeacon);
            viewSent = true;
          }
        });
      }
    }

    const _addBlocksTracking = function () {
      app.publish('tracking/record', 'SponsoredAds | ProductList', 'enabled');
      const productsDOM = document.querySelector(component.config.classes.productBlockListClass);
      const productsPlacement = document.querySelector(component.config.classes.productBlockPlacementClass);
      if (!productsDOM) return;

      component.sendBeacon(productsPlacement, component.config.selectors.beacons.loadBeacon);
      component.addPlacementTracking(productsPlacement);

      const sponsoredProductBlocks = Array.from(productsDOM.querySelectorAll(component.config.classes.sponsoredBlocksClass));
      sponsoredProductBlocks.forEach(productBlock => {
        const dataDiv = productBlock.firstElementChild;

        component.sendBeacon(dataDiv, component.config.selectors.beacons.loadBeacon);

        const productImageElement = productBlock.querySelector(component.config.selectors.productImage);
        productImageElement.addEventListener('click',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.clickBeacon));
        productImageElement.addEventListener('auxclick',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.clickBeacon));

        const productTitleElement = productBlock.querySelector(component.config.selectors.productTitle);
        productTitleElement.addEventListener('click',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.clickBeacon));
        productTitleElement.addEventListener('auxclick',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.clickBeacon));

        let quickbuyButton = null;

        if (document.querySelector(component.config.selectors.productQuickbuySimple)){
           quickbuyButton = productBlock.querySelector(component.config.selectors.productQuickbuySimple);
        } else {
           quickbuyButton = productBlock.querySelector(component.config.selectors.productQuickbuy);
        }

        quickbuyButton.addEventListener('click',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.basketBeacon));
        quickbuyButton.addEventListener('auxclick',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.basketBeacon));

        app.subscribe(component.config.subscribeChannels.wishlistBeacon, function(sku) {
          if(productBlock.children[0].children[0].getAttribute(component.config.selectors.productId) === sku) {
            component.sendBeacon(dataDiv, component.config.selectors.beacons.wishlistBeacon)
          }
        })

        component.addPlacementTracking(dataDiv);
      });
    };

    const _addFlagshipTracking = function () {
      const productsDOM = document.querySelector(component.config.classes.productFlagshipListClass);
      if (!productsDOM) return;

      const flagshipImage = component.element.querySelector(component.config.classes.sponsoredProductsFlagshipImage);
      flagshipImage.addEventListener('click',
        () => component.sendBeacon(flagshipImage, component.config.selectors.beacons.clickBeacon));
      flagshipImage.addEventListener('auxclick',
        () => component.sendBeacon(flagshipImage, component.config.selectors.beacons.clickBeacon));
      const sponsoredProductBlocks = Array.from(productsDOM.querySelectorAll(component.config.classes.sponsoredFlagshipItemClass));

      if(sponsoredProductBlocks.length <= 1) {
        const sliderButton = component.element.querySelectorAll(component.config.classes.sliderButton);
        sliderButton.forEach(button => {
          button.style.display = 'none';
        });
      }

      sponsoredProductBlocks.forEach(productBlock => {

        component.sendBeacon(productBlock, component.config.selectors.beacons.loadBeacon);

        productBlock.addEventListener('click',
          () => component.sendBeacon(productBlock, component.config.selectors.beacons.clickBeacon));
        productBlock.addEventListener('auxclick',
          () => component.sendBeacon(productBlock, component.config.selectors.beacons.clickBeacon));
      });
      component.flagshipPlacementTracking();
    };

    const _flagshipPlacementTracking = function() {
      const numberOfSlides = document.querySelectorAll('.promoProductSlider_slideItem.flagship').length
      component.numberOfBeaconSent = 0

      document.addEventListener('scroll', component.viewBeaconsViewport);

      component.viewBeaconsViewport();

      app.subscribe('promoProductSlider/moveSlides', function(el) {
        const noOfProducts = component.element.querySelectorAll('.productBlock ')
        if(component.numberOfBeaconSent <= noOfProducts.length && !component.elementArray.includes(el)  && component.numberOfBeaconSent !== numberOfSlides) {
          component.sendBeacon(el, component.config.selectors.beacons.viewBeacon);
          component.numberOfBeaconSent = component.numberOfBeaconSent + 1
          component.elementArray.push(el);
        }
      })
    }

    const _viewBeaconsViewport = () => {
      const flagshipContainer = document.querySelector(component.config.classes.productFlagshipListClass);
      let activeSlide = document.querySelector(component.config.classes.sliderCurrent);
      if(viewport.isElementFullyVisible(flagshipContainer)){
        switch(true) {
          case $window.innerWidth <= 520 ||
          $window.innerWidth <= 1100 && $window.innerWidth > 760:
            component.sendBeacon(activeSlide, component.config.selectors.beacons.viewBeacon);
            component.elementArray.push(activeSlide)
            component.numberOfBeaconSent = 1;
            break;
          case $window.innerWidth > 520 && $window.innerWidth <= 759 ||
          $window.innerWidth > 1100:
            component.sendBeacon(activeSlide, component.config.selectors.beacons.viewBeacon);
            component.sendBeacon(activeSlide.nextElementSibling, component.config.selectors.beacons.viewBeacon);
            component.elementArray.push(activeSlide)
            component.elementArray.push(activeSlide.nextElementSibling)
            component.numberOfBeaconSent = 2;
            break;
        }
        document.removeEventListener('scroll', component.viewBeaconsViewport);
      }
    }


    const _addWidgetTracking = function (widgetDOM) {
      const widgetBlocks = Array.from(widgetDOM.querySelectorAll(component.config.classes.widgetBlockClass));
      widgetBlocks.forEach(productBlock => {
        component.sendBeacon(productBlock, component.config.selectors.beacons.loadBeacon);

        const productImageElement = productBlock.querySelector(component.config.selectors.productImage);
        productImageElement.addEventListener('click',
          () => component.sendBeacon(productBlock, component.config.selectors.beacons.clickBeacon));
        productImageElement.addEventListener('auxclick',
          () => component.sendBeacon(productBlock, component.config.selectors.beacons.clickBeacon));

        const productTitleElement = productBlock.querySelector(component.config.selectors.productTitle);
        productTitleElement.addEventListener('click',
          () => component.sendBeacon(productBlock, component.config.selectors.beacons.clickBeacon));
        productTitleElement.addEventListener('auxclick',
          () => component.sendBeacon(productBlock, component.config.selectors.beacons.clickBeacon));
      });
    }

    const _sendBeacon = function (dataDiv, beaconType) {
      let beacon;
      try {
        beacon = JSON.parse(atob(dataDiv.getAttribute(beaconType)))['url'];
      } catch(error) {
        return;
      }

      let beaconStatus = navigator.sendBeacon(beacon);

      if (beaconStatus && siteObj.debugFlag) {
        $console.log('Beacon sent: ' + beacon + beaconType);
      }
    }



    component.config = _config;
    component.init = _init;
    component.successHandler = _successHandler;
    component.widgetSuccessHandler = _widgetSuccessHandler;
    component.addBlocksTracking = _addBlocksTracking;
    component.addFlagshipTracking = _addFlagshipTracking;
    component.addWidgetTracking = _addWidgetTracking;
    component.addPlacementTracking = _addPlacementTracking;
    component.flagshipPlacementTracking = _flagshipPlacementTracking;
    component.sendBeacon = _sendBeacon;
    component.viewBeaconsViewport = _viewBeaconsViewport;
    return component;
  };

  return sponsoredProductsCriteo;
});
